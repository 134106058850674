import * as React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface InputProps {
  placeholder: string;
  name: string;
  label: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isError?: boolean;
  disabled?: boolean;
}

const InputPhone: React.FC<InputProps> = (props) => {
  return (
    <div className="flex flex-col justify-start items-start gap-3 w-full">
      <label
        htmlFor={props.name}
        className="text-sm font-sfpro text-inawo-grey-200"
      >
        {props.label}
      </label>
      <meta name="format-detection" content="telephone=no" />
      <PhoneInput
        country={"ng"}
        preferredCountries={["ng", "gb", "us"]}
        containerClass="w-full !rounded-none !border-0 !border-b !border-gray-200 focus-within:!border-inawo-green !transition"
        inputClass="!w-full !rounded-none !h-[49px] !border-0 !text-base !bg-white focus:ring-0"
        buttonClass="!border-0 !bg-white !rounded-none"
        searchClass="!bg-white !border-0"
        dropdownClass=""
        autoFormat={true}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
        onChange={(_value, _country, _e, formattedValue) =>
          props.setValue(`${formattedValue}`.replaceAll(/\s/g, ""))
        }
      />
    </div>
  );
};

export default InputPhone;
